import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled, { css } from 'styled-components'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 19,
    border: '1px solid #dadde9',
  },
}));

export const Header = (props) => {

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>

<p><iframe width="560" height="315" src="https://www.youtube.com/embed/r0k-4dRP-kQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

<div className="lyrics"> 
<div className="songtitle">Velvet Shoes by Randall Thompson</div>
<table>
<tr>
<td width="70%" valign="top">
<b><div className="speech">Lyrics:</div></b>

<div className="lyrics">
<div>Let us walk in the white snow</div>
<div>In a soundless space;</div>
<div>With footsteps quiet and slow</div>
<div>At a tranquil pace</div>
<div>Under veils of white lace</div>
<div></div>
<div>I shall go shod in silk</div>
<div>And you in wool</div>
<div>White as the white cow’s milk</div>
<div>More beautiful</div>
<div>Than the wings of a gull</div>
<div>We shall walk in velvet shoes</div>
<div>Wherever we go</div>
<div>Silence will fall like dews</div>
<div>On white silence below</div>
<div>We shall walk in the snow</div>
<div>We shall walk in the snow</div>
</div>
</td>
<td valign="top">
   <table>
      <tr><td><div className="speech"><b>Verbs:</b><div>let, walk, shall, go, shod, will, fall</div></div></td></tr>
      <tr><td><div className="speech"><b>Prepositions:</b><div>in, with, at, under, of, than, like, on, below</div></div></td></tr>
      <tr><td><div className="speech"><b>Conjunctions:</b><div>and</div></div></td></tr>
      <tr><td><div className="speech"><b>Articles:</b><div>the, a</div></div></td></tr>
      <tr><td><div className="speech"><b>Adverbs:</b><div>more, as, <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">wherever</Typography>
            <b>{"adverb:"}</b> {"in or to whatever place (emphasizing a lack of restriction)."}
          </React.Fragment>
        }
      >
        <Button className="buttonW"><div className="word">wherever</div></Button>
      </HtmlTooltip></div></div></td></tr>
    <tr><td><div className="speech"><b>Pronouns:</b><div>us, I, you, we</div></div></td></tr>
    <tr><td><div className="speech"><b>Nouns:</b>
      <div>snow, space, footsteps, pace, veils, lace, silk, wool, cow, milk, wings, gull, shoes, silence, dews</div></div></td></tr>
    <tr><td><div className="speech"><b>Adjectives:</b><div>white, soundless, quiet, slow, tranquil, beautiful, velvet</div></div></td></tr>
  </table>
</td>
</tr>
</table>
</div>

<div className="concert">
<div className="concertitem"><center><b><ul>Concert Order: </ul></b><a href="https://docs.google.com/document/d/1LhZ65IdL_2w55Oy-S49MKAAaGO51zseCNPJ9eJz7fh4/edit?usp=sharing">Click here to access Lyrics Document</a></center></div>
<div className="concertitem"><table><tr><td><i>Happy Holidays to You:</i></td><td>All Voices</td></tr>
<tr><td><i>Home and the Heartland:</i></td><td>4th Grade Choir, MS &amp; HS Choirs, Radha and Saheb</td></tr>
<tr><td><i>Velvet Shoes:</i></td><td>4th Grade Choir and ballet dancers</td></tr>
<tr><td><i>Multilingual Holiday:</i></td><td>MS &amp; HS Choirs</td></tr>
<tr><td><i>Winter Bells:</i></td><td>All Voices</td></tr>
<tr><td><i>Duel of the Fates:</i></td><td>MS &amp; HS Choirs, Guest Singers</td></tr>
</table>
</div>


<div className="concert">
<iframe width="560" height="315" src="https://www.youtube.com/embed/jvU7lAcpkLo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>

<div className="concert">
<div><b>All MS and HS  listen to these tracks:</b></div>
<div><dd><a href="Happy Holidays to You.mp3">Happy Holidays to You</a></dd></div>
<div><dd><a href="Magister Comer.mp3">Latin Verse</a></dd></div>
<div><dd><a href="Spanish Verse.mp3">Spanish Verse</a></dd></div>
<div><dd><a href="Winter Bells.mp3">Winter Bells</a></dd></div>
<div><dd><a href="Ukrainian Verse.mp3">Ukrainian Verse</a></dd></div>
</div>

<div className="concert">
<div><b>Soprano:</b></div>
<div><dd><a href="H-H Chorus Soprano Vocal.mp3">H-H Chorus Soprano Vocal</a></dd></div>
<div><dd><a href="H-H V1 Chorus Soprano.mp3">H-H V1 Chorus Soprano</a></dd></div>
<div><dd><a href="H-H Chorus Sop + Alto.mp3">H-H Chorus Sop + Alto</a></dd></div>
<div><dd><a href="Second Soprano Duel.mp3">Second Soprano Duel</a></dd></div>
<div><dd><a href="First Soprano Duel Part 2.mp3">First Soprano Duel Part 1</a></dd></div>
<div><dd><a href="First Soprano Duel Part 2.mp3">First Soprano Duel Part 2</a></dd></div>
<div><dd><a href="French Verse.mp3">French Verse</a></dd></div>
</div>

<div className="concert">
<div><b>Alto:</b></div>
<div><dd><a href="H-H Chorus Alto Vocal.mp3">H-H Chorus Alto Vocal</a></dd></div>
<div><dd><a href="H-H V1 Chorus Alto.mp3">H-H V1 Chorus Alto</a></dd></div>
<div><dd><a href="H-H Chorus Sop + Alto.mp3">H-H Chorus Sop + Alto</a></dd></div>
<div><dd><a href="Alto Duel.mp3">Alto Duel</a></dd></div>
<div><dd><a href="French Verse.mp3">French Verse</a></dd></div>
</div>

<div className="concert">
<div><b>Tenor:</b></div>
<div><dd><a href="H-H Chorus Tenor.mp3">H-H Chorus Tenor</a></dd></div>
<div><dd><a href="Tenor Duel.mp3">Tenor Duel</a></dd></div>
<div><dd><a href="German Verse.mp3">German Verse</a></dd></div>
<div><dd><a href="German Verse Vocal.mp3">German Verse Vocal</a></dd></div>
<div><dd><a href="H-H Tenor Vocal.mp3">H-H Tenor Vocal</a></dd></div>
</div>

<div className="concert">
<div><b>Bass:</b></div>
<div><dd><a href="H-H Chorus Bass.mp3">H-H Chorus Bass</a></dd></div>
<div><dd><a href="Bass Duel.mp3">Bass Duel</a></dd></div>
<div><dd><a href="German Verse.mp3">German Verse</a></dd></div>
<div><dd><a href="German Verse Vocal.mp3">German Verse Vocal</a></dd></div>
<div><dd><a href="H-H Bass Vocal.mp3">H-H Bass Vocal</a></dd></div>
</div>

<div className="concert">
<div><b>4th Grade Choir:</b></div>
<div><dd><a href="Happy Holidays to You.mp3">Happy Holidays to You</a></dd></div>
<div><dd><a href="H-H V1 Chorus Soprano.mp3">H-H V1 Chorus Soprano</a></dd></div>
<div><dd><a href="4th-Elem Choirs Winter Bells.mp3">4th-Elem Choirs Winter Bells</a></dd></div>
</div>

</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

 
